import React, { useEffect, useState } from "react";
import Style from "./Checkout.module.scss";
import CheckoutFor from "./CheckoutFor";
import OrderSummary from "./OrderSummary";
import CheckoutPayment from "./CheckoutPayment";
import { useStorePurchase } from "../../logic/useStorePurchase";
import { useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import useCardData from "../../logic/useCardData";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getPaymentStatus } from "../../store/slices/paymentStatusSlice";
import VatAlert from "./VatAlert";
import GiftCardSummary from "./GiftcardSummary";
import i18n from "../../i18n";

let count = 0;
let aa = 0;

const Checkout = () => {
  const [paymentStatus, setPaymentStatus] = useState({});

  const [pendingModal, setPendingModal] = useState(false);

  const { t } = useTranslation(["common"]);

  const navigate = useNavigate();

  const { slug, lang: langSlug } = useParams();
  const { fetchCardDetails, details } = useStorePurchase();

  const {
    show,
    setShow,
    iFrame,
    paymentError,
    loadingShimmer,
    formInitialised,
    paymentConfigure,
    setShowModalFailed,
    iFrameNull,
    setIFrameNull,
  } = useCardData(details?.session_id);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    fetchCardDetails(slug);
  }, []);

  const lang = localStorage.getItem("language_type");

  useEffect(() => {
    if (langSlug === "ar") {
      localStorage.setItem("language_type", "ar");
    } else if (langSlug === "en") {
      localStorage.setItem("language_type", "en");
    } else {
      localStorage.setItem("language_type", "en");
    }
  }, [langSlug]);

  useEffect(() => {
    if (!langSlug) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(langSlug);
  }, [langSlug]);

  useEffect(() => {
    if (lang === "ar") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "rtl");
    } else if (lang === "en") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "ltr");
    } else {
      localStorage.setItem("language_type", "en");
    }
  }, [lang]);

  const dispatch = useDispatch();

  const PaymentCall = (id) => {
    console.log(id);

    dispatch(getPaymentStatus(id))
      .unwrap()
      .then((value) => {
        if (value?.data?.data?.payment_status == 0) {
          setPendingModal(true);
          PaymentCall(id);
        } else {
          setPaymentStatus(value?.data?.data?.payment_status);
        }
      })
      .catch(() => {
        setTimeout(() => {
          alert(t("Something_wrong"));
          setPendingModal(false);
          setShow(false);
          if (lang === "ar") {
            navigate("/ar");
            window.location.reload();
          } else if (lang === "en") {
            navigate("/en");
            window.location.reload();
          } else {
            navigate("/en");
          }
        }, 6000);
      });
  };

  useEffect(() => {
    if (iFrame) {
      const formId = document.getElementById("threedsChallengeRedirectForm");
      const formId2 = document.getElementById("redirectTo3ds1Form");
      const formCancel = document.getElementById(
        "threedsFrictionLessRedirectForm"
      );
      if (formId) {
        formId.submit();
      } else if (formId2) {
        formId2.submit();
      } else if (formCancel) {
        formCancel.submit();
      }
      const iframeDiv = document.getElementById("challengeFrame");
      const iframeDiv2 = document.getElementById("redirectTo3ds1Frame");
      const formCancelDiv = document.getElementById("challengeFrame");
      if (iframeDiv && !formCancel) {
        iframeDiv.addEventListener("load", (e) => {
          if (count > 1) {
            document.getElementById("threedsChallengeRedirect").style.height =
              "100vh";

            PaymentCall(details?.id);
          } else count++;
        });
      } else if (iframeDiv2) {
        iframeDiv2.addEventListener("load", (e) => {
          if (count <= 1) {
            document.getElementById("redirectTo3ds1Frame").style.height =
              "100vh";
          }
          if (count > 1) {
            document.getElementById("redirectTo3ds1Frame").style.height =
              "100vh";

            PaymentCall(details?.id);
          } else count++;
        });
      } else if (formCancelDiv && formCancel) {
        formCancelDiv.addEventListener("load", (e) => {
          if (count == 0) {
            document.getElementById(
              "threedsFrictionLessRedirectForm"
            ).style.height = "100vh";
            PaymentCall(details?.id);
          } else count++;
        });
      }
    }
  }, [iFrame]);

  useEffect(() => {
    if (paymentStatus == 1) {
      setPendingModal(false);
      setShow(false);
      navigate(`/order-summary/${details?.id}/${lang}`, {
        state: { details },
      });
    } else if (paymentStatus == 2) {
      setPendingModal(false);
      setShow(false);
      navigate(`/order-summary/${details?.id}/${lang}`, {
        state: { details },
      });
    }
  }, [paymentStatus]);

  const handleHide = () => {
    setShow(false);
    const lang = localStorage.getItem("language_type");
    if (lang === "ar") {
      navigate("/ar");
      window.location.reload();
    } else {
      navigate("/en");
      window.location.reload();
    }
  };

  useEffect(() => {
    if (formInitialised && details?.session_id) {
      // setTimeout(() => {
      paymentConfigure(details?.session_id);
      // }, 2000);
    }
  }, [formInitialised, details?.session_id]);

  return (
    <section className={Style.checkout_page}>
      {typeof paymentStatus !== "object" ? (
        <Modal
          show={show}
          centered
          className={`${Style.checkout_modal}`}
        ></Modal>
      ) : (
        <Modal
          show={show}
          centered
          className={`${Style.checkout_iframe_modal} ${
            pendingModal && Style.bg_transparent
          }`}
        >
          {pendingModal && (
            <>
              <Modal.Header
                onClick={handleHide}
                className={Style.checkout_modal_header}
              ></Modal.Header>
              <Modal.Body
                className={`text-center ${Style.checkout_modal_body}`}
              >
                <div className={`${Style.message_title} ${Style.spinner_wrap}`}>
                  <div className="html-spinner"></div>
                </div>
              </Modal.Body>
            </>
          )}

          {iFrame && !pendingModal && (
            <div
              className="modal-body"
              dangerouslySetInnerHTML={{ __html: iFrame }}
            />
          )}
        </Modal>
      )}
      <div className="giftCard_container">
        <div className={Style.checkout_container}>
          <h1 className={Style.checkout_page_title}>{t("CHECKOUT")}</h1>
          <div className={`row`}>
            <div className="col-12 col-md-5 col-xl-6">
              <div className={Style.checkout_left}>
                <CheckoutFor data={details} />
              </div>
            </div>
            <div className="col-12 col-md-7 col-xl-6">
              <div className={Style.checkout_right}>
                <GiftCardSummary data={details} />
                <VatAlert />
                <CheckoutPayment
                  data={details}
                  paymentError={paymentError}
                  loadingShimmer={loadingShimmer}
                  iFrameNull={iFrameNull}
                  setIFrameNull={setIFrameNull}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Checkout;
