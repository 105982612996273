import { useEffect, useState } from "react";
import { useStorePurchase } from "../../logic/useStorePurchase";
import GiftCardsDelivery from "./GiftCardsDelivery";
import GiftCardsDesign from "./GiftCardsDesign";
import GiftCardsPersonalise from "./GiftCardsPersonalise";
import GiftCardsValue from "./GiftCardsValue";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useSelector, useDispatch } from "react-redux";
import { saveFormValue } from "../../store/slices/formPersistSlice";
import { useParams, useNavigate } from "react-router-dom";
import "moment-timezone";
import { useToken } from "../../logic/useToken";
import { useListAmount } from "../../logic/useListAmount";
import Style from "./GiftCards.module.scss";

const GiftCards = () => {
  const dispatch = useDispatch();
  const { lang: langSlug } = useParams();
  const lang = localStorage.getItem("language_type");

  const navigate = useNavigate();
  const { tab, setTab } = useStorePurchase();
  
  const { listAmountData } = useListAmount();

  const REACT_APP_MASTERCARD_GATEWAY = process.env.REACT_APP_MASTERCARD_GATEWAY;

  const { basicData, getTokenFromApi, getBasicDetails } = useToken();

  useEffect(() => {
    if (langSlug === "ar") {
      localStorage.setItem("language_type", "ar");
    } else if (langSlug === "en") {
      localStorage.setItem("language_type", "en");
    } else {
      localStorage.setItem("language_type", "en");
    }
  }, []);
  
  useEffect(() => {
    if (!langSlug) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem("language_type");

    getTokenFromApi();
    getBasicDetails(lang);
  }, []);

  useEffect(() => {
    i18n.changeLanguage(langSlug);
  }, [langSlug]);

  useEffect(() => {
    const lang = localStorage.getItem("language_type");
    if (lang === "ar") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "rtl");
    } else if (lang === "en") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "ltr");
    }
  }, []);

  const { formSubmitSender, formSubmitRecipient, loader, responseError } =
    useStorePurchase();

  const { t } = useTranslation(["common"]);

  var numbers = /^\d+\.?\d*$/;

  const names = /^[aA-zZ\s]+$/;

  const formValue = useSelector((state) => state.formData);

  function between_amount(message) {
    return this.test("isValidCountry", message, function (value) {
      const { path, createError } = this;

      if (
        value < parseInt(listAmountData?.min_limit) ||
        value > parseInt(listAmountData?.max_limit)
      ) {
        return createError({
          path,
          message: message ?? "Please enter an amount between 50 and 5000",
        });
      }

      return true;
    });
  }

  Yup.addMethod(Yup.string, "between_amount", between_amount);

  const validationSender = Yup.object().shape({
    giftCard: Yup.string().required(t("select_Card")),
    type: Yup.string().required(t("Select_type")),
    senderName: Yup.string().required(t("Name_Required")).matches(names, t("only_alpha")),
    senderEmail: Yup.string().email(t("Invalid_email")).required(t("Email_Required")),
    amount: Yup.string()
      .matches(numbers, t("Be_A_Number"))
      .required(t("Amount_Required"))
      .between_amount(
        langSlug === "en"
          ? ` Please enter an amount between ${parseInt(listAmountData?.min_limit)} and ${parseInt(
              listAmountData?.max_limit
            )} `
          : `الرجاء إدخال مبلغ يتراوح بين ${parseInt(listAmountData?.min_limit)} و${parseInt(
              listAmountData?.max_limit
            )} `
      ),
    senderMobile: Yup.string()
      .matches(numbers, t("Invalid_mobile"))
      .required(t("Mobile_required"))
      .min(9, t("min"))
      .max(10, t("max")),
  });

  const validationRecipient = Yup.object().shape({
    giftCard: Yup.string().required(t("select_Card")),
    type: Yup.string().required(t("Select_type")),
    recpName: Yup.string().required(t("Name_Required")),
    senderRecpName: Yup.string().required(t("Name_Required")).matches(names, t("only_alpha")),
    recpEmail: Yup.string().email(t("Invalid_email")).required(t("Email_Required")),
    amount: Yup.string()
      .matches(numbers, t("Be_A_Number"))
      .required(t("Amount_Required"))
      .between_amount(
        langSlug === "en"
          ? ` Please enter an amount between ${parseInt(listAmountData?.min_limit)} and ${parseInt(
              listAmountData?.max_limit
            )} `
          : `الرجاء إدخال مبلغ يتراوح بين ${parseInt(listAmountData?.min_limit)} و${parseInt(
              listAmountData?.max_limit
            )} `
      ),
    recpmobile: Yup.string()
    .matches(numbers, t("Invalid_mobile"))
    .required(t("Mobile_required"))
    .min(9, t("min"))
    .max(10, t("max")),
  });

  
  const formik = useFormik({
    initialValues:
      Object.keys(formValue.formData).length === 0
        ? {
            senderName: "",
            senderEmail: "",
            senderMobile: "",
            recpName: "",
            recpEmail: "",
            recpmobile: "",
            senderRecpName: "",
            giftCard: "",
            amount: "",
            message: "",
            type: 1,
            date: moment().add(1, "d").toDate(),
            radioType: tab,
          }
        : formValue.formData,

    validationSchema: tab === "1" ? validationSender : validationRecipient,

    onSubmit: (values) => {
      
      if (tab === "1") {
        dispatch(saveFormValue(values));
        let scrptEl = document.createElement("script");
        scrptEl.src = REACT_APP_MASTERCARD_GATEWAY;

        scrptEl.async = true;
        document.body.appendChild(scrptEl);
        let dateData = {
          ...values,
          date:
            formik.values.type === 1
              ? moment().tz("Asia/Dubai").format("YYYY-MM-DD")
              : moment(formik.values.date).format("YYYY-MM-DD"),
        };
        formSubmitSender(dateData, tab);
      } else {
        dispatch(saveFormValue(values));
        let scrptEl = document.createElement("script");
        scrptEl.src = REACT_APP_MASTERCARD_GATEWAY;

        scrptEl.async = true;
        document.body.appendChild(scrptEl);
        let dateData = {
          ...values,
          date:
            formik.values.type === 1
              ? moment().tz("Asia/Dubai").format("YYYY-MM-DD")
              : moment(formik.values.date).format("YYYY-MM-DD"),
        };
        formSubmitRecipient(dateData, tab);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <GiftCardsDesign formik={formik} basicData={basicData} />
      <div className={Style.gift_card_value}>
        <div className="giftCard_container">
          <div className={Style.gift_card_value_container}>
            <GiftCardsValue formik={formik} basicData={basicData} langSlug={langSlug} />
            <GiftCardsPersonalise
              tab={tab}
              formik={formik}
              setTab={setTab}
              loader={loader}
              basicData={basicData}
              responseError={responseError}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default GiftCards;
